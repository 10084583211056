export const MESSAGE = {
  PAYMENT_SUCCESS: '상품 주문이 완료되었습니다.',
  POLICY_PAYMENT_REQUIRED: '결제 진행 필수 동의에 체크해주세요.',
  BANK_ACCOUNT_REQUIRED: '입금자명을 입력해주세요.',
  BANK_ID_REQUIRED: '입금 은행을 선택해주세요.',
  PAYMENT_METHOD_REQUIRED: '결제 수단을 선택해주세요.',
  SHIPPING_TIME_REQUIRED: '배달 요청 시간을 설정해주세요.',
  ORDER_NAME_REQUIRED: '구매자명을 입력해주세요.',
  ADDRESS_REQUIRED: '주소를 설정해 주세요.',
  ORDER_NAME_MAX_LENGTH: 'The order_name may not be greater than 20 characters.',
  ADD_CART_BY_ORDER_SUCCESS: 'ADD_CART_BY_ORDER_SUCCESS',
  UPDATE_ADDRESS_SUCCESS: '주소가 설정되었습니다',
  UPDATE_ADDRESS_ERROR: 'UPDATE_ADDRESS_ERROR',
  PAYMENT_NOT_LESS_50: '* 5만원 이상 구매 가능합니다.',
  ADDRESS_CREATE_SUCCESS: "주소가 설정되었습니다.",
  PRODUCT_NOT_FOUND: "상품이 삭제되었습니다.",
  MAX_EXCEED: "메뉴별로 최대 100개 까지 주문하실 수 있습니다. \n메뉴 수량을 변경해주세요.",
  ADD_CART_SUCCESS: "장바구니에 담았어요",
  CARD_NUMBER_REQUIRED: "카드번호를 입력해주세요.",
  EXPIRY_REQUIRED: "유효기간을 입력해주세요.",
  INSTALL_MONTH_REQUIRED: "INSTALL_MONTH_REQUIRED",
};

export const MINIMUM_PAYMENT = 50000

export const PAYMENT_METHOD = [
  { id: 1, label: "신용카드", value: 'CARD' },
  { id: 2, label: "신용카드 (현장 결제)", value: 'COD' },
  { id: 4, label: "신용카드 (수기 결제)", value: 'CARD_MANUAL' },
  { id: 3, label: "무통장입금", value: 'MONEY' },
];


    // terms-of-service
    // privacy-policy
    // delivery-product-precautions
    // electronic-financial
    // delivery-and-payment
    // cancellation-refund

export const POLICY_PAYMENT = [
  {
    id: 1,
    label: "(필수) 서비스 이용약관",
    title: "이용약관",
    value: "terms-of-service",
    hasCheck: true,
  },
  {
    id: 2,
    label: "(필수) 개인정보 처리 방침",
    title: "개인정보 처리 방침",
    value: "privacy-policy",
    hasCheck: true,
  },
  {
    id: 3,
    label: "(필수) 배달상품 주의 사항",
    title: "배달상품 주의 사항",
    value: "delivery-product-precautions",
    hasCheck: true,
  },
  {
    id: 4,
    label: "(필수) 전자금융거래 이용약관",
    title: "전자금융거래 이용약관",
    value: "electronic-financial",
    hasCheck: true,
  },
  {
    id: 5,
    label: "배송 및 결제 안내",
    title: "배송 및 결제 안내",
    value: "delivery-and-payment",
    hasCheck: false,
  },
  {
    id: 6,
    label: "취소/환불 안내",
    title: "취소/환불 안내",
    value: "cancellation-refund",
    hasCheck: false,
  },
];


export const DELIVERY_DATA = [
  { id: 1, label: "초인종 누르고 문 앞" },
  { id: 2, label: "초인종 누르지 말고 문 앞" },
  { id: 3, label: "직접 수령 (부재시 문 앞)" },
  { id: 4, label: "도착하기 전 전화" },
  { id: 5, label: "도착하면 전화" },
  { id: 6, label: "요청사항 없음" },
  { id: 7, label: "직접 작성" },
];

export const ACCUMULATE_STATE = {
  GRANT: {
    SIGNUP_CREDITS: {
      title: "회원가입",
      icon: "ACMICON" 
    },
    SIGNUP_REFERRAL_CREDITS: {
      title: "추천인 가입",
      icon: "modules/Accumulate/assets/icon/income.svg" 
    },
    PURCHASE_CREDITS: {
      title: "사용 적립",
      icon: "modules/Accumulate/assets/icon/income.svg"
    },
    PURCHASE_REFERRAL_CREDITS: {
      title: "추천인 구매 적립",
      icon: "modules/Accumulate/assets/icon/income.svg"
    },
    MANUAL_CREDITS: {
      title: "관리자 지급",
      icon: "modules/Accumulate/assets/icon/income.svg"
    }
  },
  REVOKE: {
    SIGNUP_CREDITS: {
      title: "회원가입",
      icon: "modules/Accumulate/assets/icon/request.svg" 
    },
    SIGNUP_REFERRAL_CREDITS: {
      title: "추천인 가입",
      icon: "modules/Accumulate/assets/icon/request.svg" 
    },
    PURCHASE_CREDITS: {
      title: "사용 적립",
      icon: "modules/Accumulate/assets/icon/request.svg"
    },
    PURCHASE_REFERRAL_CREDITS: {
      title: "추천인 구매 적립",
      icon: "modules/Accumulate/assets/icon/request.svg"
    },
    MANUAL_CREDITS: {
      title: "관리자 회수",
      icon: "modules/Accumulate/assets/icon/request.svg"
    },
    REQUEST_WITHDRAW_CREDITS: {
      title: "출금 신청",
      icon: "modules/Accumulate/assets/icon/withdraw.svg"
    },
    PAY_WITHDRAW_CREDITS: {
      title: "출금 신청",
      icon: "modules/Accumulate/assets/icon/withdraw.svg"
    }
  },
} as any;

export const PRODUCT_STATE = {
  AVAILABLE : 'AVAILABLE',
  UNAVAILABLE : 'UNAVAILABLE',
  OUTOFSTOCK : 'OUTOFSTOCK',
};