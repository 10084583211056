import CDialog from "core/components/CDialog";
import IconHeader from "core/components/header/IconHeader";
import { useFetch } from "core/hooks/useFetch";
import AppLayout from "core/layouts/AppLayout";
import API from "core/networks/API";
import { RouterName } from "core/router";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { API_ORDER_HISTORY } from "../api";
import "../assets/scss/ordercancel.scss";
import OrderCancelStep1 from "../components/OrderCancelStep1";
import OrderCancelStep2 from "../components/OrderCancelStep2";
import { useCancelOrder } from "../hooks/useLists";
import orderCancelValidationSchema from "../utils/orderCancelValidationSchema";
import { hideBottomButtonInputFocus } from "core/utils/helper";

const DataReasonCancel = [
  {
    id: 1,
    reason: '단순 변심'
  },
  {
    id: 2,
    reason: '다른 상품으로 재 주문 예정'
  },
  {
    id: 3,
    reason: '직접입력'
  }
]

const OrderCancel = (props: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isBackMain, setIsBackMain] = useState<boolean>(false);
  const [dataPopup, setDataPopup] = useState<any>({
    isShow: false,
    msg: '',
    type: '',
    textCancel: '',
    textSubmit: ''
  })

  const [invalid, setInvalid] = useState<boolean>(false)

  const [paramsDetail] = useState<any>({
    id: params?.id,
    'relation[]': ['creator', 'items', 'shippingApp']
  });

  const {
    data: orderDetail,
    loading: loadingDetail,
    error: errorDetail,
  } = useFetch({ url: API_ORDER_HISTORY.GET_DETAIL, options: paramsDetail });


  const [dataCancel, setDataCancel] = useState<any>({
    reason: "",
    content: "",
    id: '',
    order_id: params?.id,
    bank_id: "",
    bank_account_name: "",
    bank_account_number: "",
    isCheckBank: orderDetail?.data?.payment_status === "COMPLETED" && orderDetail?.data?.payment_method === 'MONEY' ? "PAID" : "UNPAID",
  })

  useEffect(() => {
    hideBottomButtonInputFocus('.bottom-btn-fixed')
  }, [])

  useEffect(() => {
    if (orderDetail?.data?.id) {
      setDataCancel({
        ...dataCancel,
        isCheckBank: orderDetail?.data?.payment_status === "COMPLETED" && orderDetail?.data?.payment_method === 'MONEY' ? "PAID" : "UNPAID",
      })
    }
  }, [orderDetail])


  useEffect(() => {
    orderCancelValidationSchema.validate(dataCancel)
    .then(function (dataInvalid: any) {
      setInvalid(false);
    })
    .catch(function (err: any) {
      setInvalid(true);
    });
  }, [dataCancel])

  const checkObjectNotEmpty = (obj: any) => {
    return Object.values(obj).find((item:any) => item !== "");
  }

  const checkIsDusty = (data: any) => {
    if (data.isCheckBank === "PAID") {
      return checkObjectNotEmpty({
        id: data.id,
        bank_id: data.bank_id,
        bank_account_name: data.bank_account_name,
        bank_account_number: data.bank_account_number,
      })
    }else{
      return checkObjectNotEmpty({
        id: data.id,
      })
    }
  }
  
  const handleClickLeftBtn = (step: any, id: any) => {
    let params_url = searchParams.toString();
    if (step === '2') {
      navigate(`${RouterName.ORDER_CANCEL.replace(":id", params?.id)}?${params_url.replace('step=2', 'step=1')}`);
    }else{
      const dusty = checkIsDusty(dataCancel);
      if (dusty && dusty !== undefined) {
        setDataPopup({
          isShow: true,
          msg: '작성을 취소 하시겠습니까?',
          type: 'CANCEL_REASON',
          textCancel: '닫기',
          textSubmit: '작성 취소'
        })
      }else{
        console.log('params_url', searchParams.get('from'));
        
        //redirect two case
        // navigate(RouterName.ORDER_HISTORY_LIST)
        // navigate(RouterName.ORDER_HISTORY_DETAIL)
        navigate(`${searchParams.get('from')}`, {
          state: {
            back_type: RouterName.ORDER_CANCEL, 
            back_url: RouterName.MY_INFO
          }
        });
      }
      
    }
  }

  const goToMain = () => {
    setIsBackMain(true);
    const dusty = checkIsDusty(dataCancel);
      if (dusty && dusty !== undefined) {
        setDataPopup({
          isShow: true,
          msg: '작성을 취소 하시겠습니까?',
          type: 'CANCEL_REASON',
          textCancel: '닫기',
          textSubmit: '작성 취소'
        })
      }else{
        navigate(RouterName.MAIN);
      }
  }

  (window as any).backFromDeviceAndroid = function() {
    //code webview
    handleClickLeftBtn(searchParams.get("step"), searchParams.get("id"))
  }


  const handleClickRightBtn = (step: any) => {
    if (step === '1') {
      searchParams.set('step', '2');
      setSearchParams(searchParams, { replace: true })
    }else{
      setDataPopup({
        isShow: true,
        msg: '주문을 취소 하시겠습니까?',
        type: 'CANCEL_ORDER',
        textCancel: '닫기',
        textSubmit: '주문취소'
      })
    }
  }

  const sendDataStep1 = (data: any) => {
    searchParams.set('id', data?.id);
    setSearchParams(searchParams, { replace: true })
    setDataCancel((p: any) => ({
      ...p,
      ...data
    }))
  }

  const handleClose = (data: any) => {
    setIsBackMain(false);
    setDataPopup({
      isShow: false,
      msg: '',
      type: 'CANCEL_REASON',
      textCancel: '닫기',
      textSubmit: '작성 취소'
    })
    
  }

  const _onSuccess = () => {
    // let url: any = searchParams.get('from');
    navigate(`${searchParams.get('from')}`, {
      state: {
        back_type: RouterName.ORDER_CANCEL, 
        back_url: RouterName.MY_INFO
      }
    });

  }

  const _onFailed = (errors: any) => {
    if (errors && errors?.code === 5000) {
      setDataPopup({
        isShow: true,
        msg: errors?.msg,
        type: 'CANCEL_REASON_FAILED',
        textSubmit: '확인',
      })  
    }else{
      setDataPopup({
        isShow: true,
        msg: 'ERRORS',
        type: 'CANCEL_REASON',
        textCancel: '닫기',
      })
    }
  }

  const handleCancelOrder = useCancelOrder({onSuccess: _onSuccess, onFailed: _onFailed})

  const cancelOrderApi = async (data: any) => {
    let _data = {
      reason: data.id === 3 ? data.content : data.reason,
      order_id: +data.order_id,
      payment_method: orderDetail?.data?.payment_method,
      type:  orderDetail?.data?.payment_status === "COMPLETED" ? "PAID" : "UNPAID", // UNPAID, PAID
      isCheckBank: orderDetail?.data?.payment_status === "COMPLETED" && orderDetail?.data?.payment_method === 'MONEY' ? "PAID" : "UNPAID",
      bank_id: data.bank_id,
      bank_account_name: data.bank_account_name,
      bank_account_number: data.bank_account_number,
    }

    handleCancelOrder(_data)
    // try {
    //   let _data = {
    //     reason: data.id === 3 ? data.content : data.reason,
    //     order_id: +data.order_id
    // }
    //   const res = await API.post(API_ORDER_HISTORY.CANCEL_ORDER, _data);
    //   if (res.status === 200) {
    //     let url: any = searchParams.get('from');
    //     navigate(url)
    //   }
    // } catch (error) {
      
    // }
  }

  const handleSubmit = (data: any) => {
    if (data.type === 'CANCEL_REASON') {
      let url: any = searchParams.get('from');
      if (isBackMain) {
        navigate(RouterName.MAIN)
      }else{
        navigate(url, {
          state: {
            back_type: RouterName.ORDER_CANCEL, 
            back_url: RouterName.MY_INFO
          }
        })
      }
    }

    if (data.type === 'CANCEL_REASON_FAILED') {
      navigate(RouterName.ORDER_HISTORY_LIST)
    }
    
    if (data.type === 'CANCEL_ORDER') {
      //cancel
      cancelOrderApi(dataCancel);
    }
  }

  console.log('dataCancel', dataCancel);
  //navigate(RouterName.MAIN);
  return (
    <AppLayout className={"order-cancel-page"}>
      <IconHeader
        className="order-cancel-page__custom-header "
        title={"주문 취소"}
        id={"main-header"}
        iconLeft={true}
        handleLeftClick={() =>
          handleClickLeftBtn(searchParams.get("step"), searchParams.get("id"))
        }
        handleRightClick={() => {
          goToMain()
        }}
        iconRight={true}
      />
      <div className="order-cancel-page__content">
        <div className="form-cancel">
          {searchParams.get("step") !== null &&
            searchParams.get("step") === "1" && (
              <OrderCancelStep1
                dataReason={DataReasonCancel}
                data={dataCancel}
                orderDetail={orderDetail.data}
                sendDataStep1={sendDataStep1}
              />
            )}

          {searchParams.get("step") !== null &&
            searchParams.get("step") === "2" && (
              <OrderCancelStep2
                data={orderDetail?.data || []}
                dataStep1={dataCancel}
              />
            )}
          <div
            className={`button-group-cancel ${
              searchParams.get("step") === "1"
                ? "button-group-cancel--step1 bottom-btn-fixed"
                : "button-group-cancel--step2"
            }`}
          >
            <div className="item">
              <div className="item__left">
                <div className="d-grid">
                  <button
                    className="cbtn btn-secondary"
                    onClick={() =>
                      handleClickLeftBtn(
                        searchParams.get("step"),
                        searchParams.get("id")
                      )
                    }
                  >
                    {
                      searchParams.get("step") === "1" ? '취소' : '이전'
                    }
                  </button>
                </div>
              </div>
              <div className="item__right">
                <div className="d-grid">
                  <button
                    className="cbtn btn-primary"
                    onClick={() => handleClickRightBtn(searchParams.get("step"))}
                    disabled={invalid}
                  >
                    {
                      searchParams.get("step") === "1" ? '다음' : '주문 취소'
                    }
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CDialog
        show={dataPopup.isShow}
        overlay={true}
        closeBtn={dataPopup.textCancel}
        submitBtn={dataPopup.textSubmit}
        className={"cancel-order-dialog"}
        handleClose={() => handleClose(dataPopup)}
        handleSubmit={(e: any) => handleSubmit(dataPopup)}
      >
        <div>
          {dataPopup.msg}
        </div>
      </CDialog>
    </AppLayout>
  );
};

export default OrderCancel;
