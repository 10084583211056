import {
  ADD_CART,
  ADD_CART_FAILED,
  ADD_CART_SUCCESS,
  CANCEL_ORDER_FAILED,
  CANCEL_ORDER_REQUEST,
  CANCEL_ORDER_SUCCESS,
  DECREASE_QUANTITY,
  DELETE_CART,
  DELETE_CART_FAILED,
  DELETE_CART_SUCCESS,
  GET_LAST_ORDER,
  GET_LAST_ORDER_SUCCESS,
  GET_LIST_CART,
  GET_LIST_CART_SUCCESS,
  GET_LIST_ORDER_HISTORY_FAILED,
  GET_LIST_ORDER_HISTORY_REQUEST,
  GET_LIST_ORDER_HISTORY_SUCCESS,
  GET_STORES,
  GET_STORES_SUCCESS,
  INCREASE_QUANTITY,
  RESET_CART_ERRORS,
  RESET_DATA_PAYMENT,
  UPDATE_DATA_PAYMENT,
  UPDATE_OR_CREATE_CART,
  UPDATE_QUANTITY,
  UPDATE_RELOAD_PAGE_LIST,
  UPDATE_STATUS_PRODUCT,
} from "./types";
import { DataCarts, DataState } from "../interfaces/Types";
import { PAYMENT_METHOD } from "core/constants/Commons";

const cartsFromLocalStorage = localStorage.getItem("carts")
  ? JSON.parse(localStorage.getItem("carts") || "[]")
  : [];

const initialState: DataState = {
  cartReducer: {
    data: cartsFromLocalStorage as DataCarts,
    type: "",
    errors: null,
    loadingListCart: false,
    loadingDeleteCart: false,
  },
  orderHistoryReducer: {
    lists: {
      data: [],
      total: 0,
      totalPage: 1,
    },
    type: "",
    loadingListOrderHistory: false,
    listParams: {}
  },
  paymentReducer: {
    data: {
      orderId: null,
      buyerName: "",
      phone: "",
      shippingTime: {
        date: null,
        hour: null,
        minute: null,
      },
      paymentMethod: { id: null, label: '', value: '' },
      depositorName: "",
      policyPayment: false,
      shippingNoteContent: "",
      shippingNoteId: null,
      dataProduct: [],
      shippingAddress: {},
      stores: [],
      lastOrder: {},
      cardManualInfo: {
        card_number_1: '',
        card_number_2: '',
        card_number_3: '',
        card_number_4: '',
        expire_month: '',
        expire_year: '',
        installment_month: { id: 1, label: '일시불', value: '00'},
      },
      type: 'DETAIL_PAYMENT' // 'CART_PAYMENT'
    } as any,
    loadingStores: false,
    loadingLastOrder: false,
    type: "",
  }
};

const orderReducer = (
  state: DataState = initialState,
  action: any
): DataState => {
  switch (action.type) {
    //carts
    case GET_LIST_CART:
      return {
        ...state,
        cartReducer: {
          ...state.cartReducer,
          loadingListCart: true,
          errors: null,
          type: action.type,
        }
      };
    case GET_LIST_CART_SUCCESS:
      let dataRes = action.payload?.data || [];
      let _data = [];
      if (dataRes.length > 0) {
        _data = dataRes.map((item: any, idx: number) => {
          let idxCart = state.cartReducer.data.findIndex((el: any) => el.id === item.id);
          let flag = false;
          if (idxCart !== -1) {
            flag = true;
          }
          return {
            ...item,
            quantity: item.qty,
            prod_name: `[${item?.product?.category?.name}] ${item?.product?.name}`,
            priceByQuantity: +item?.product?.price * +item?.qty,
            prod_image: item?.product?.images[0]?.path_original,
            isSelected: flag ? state.cartReducer.data[idxCart].isSelected : true,
          };
        });
      }

      localStorage.setItem("carts", JSON.stringify(_data || []));
      return {
        ...state,
        cartReducer: {
          ...state.cartReducer,
          data: _data,
          errors: null,
          loadingListCart: false,
          type: action.type,
        }
      };
    case ADD_CART:
      let check = false;
      // const productInCart = state.cartReducer.data.find((p:any) => p.id === action.payload.id);
      // eslint-disable-next-line array-callback-return
      let _dataAddCart = [];
      _dataAddCart = state.cartReducer.data.map((item: any, key: number) => {
        if (item.prod_id === action.payload.prod_id) {
          // state.cartReducer.data[key].quantity = item.quantity + action.payload.quantity;
          // state.cartReducer.data[key].priceByQuantity = action.payload.priceByQuantity;
          check = true;
          return {
            ...item,
            quantity: +item.quantity + action.payload.quantity,
            qty: +item.quantity + action.payload.quantity,
            priceByQuantity:
              (+item.quantity + action.payload.quantity) * +item.prod_price,
          };
        }
        return item;
      });
      if (!check) {
        _dataAddCart.unshift({
          ...action.payload,
          priceByQuantity:
            +action.payload.prod_price * +action.payload.quantity,
        });
      }
      localStorage.setItem("carts", JSON.stringify(_dataAddCart));
      return {
        ...state,
        cartReducer: {
          ...state.cartReducer,
          data: _dataAddCart,
          errors: null,
          type: action.type,
        }
      };
    case ADD_CART_SUCCESS:
      let resAddCart = action.payload?.data || [];
      let _dataAddCartSuccess = [];
      _dataAddCartSuccess = state.cartReducer.data.map((item: any) => {
        if (item.prod_id === resAddCart.prod_id) {
          return {
            ...item,
            id: resAddCart.id,
          };
        }
        return item;
      });
      localStorage.setItem("carts", JSON.stringify(_dataAddCartSuccess));
      return {
        ...state,
        cartReducer: {
          ...state.cartReducer,
          data: _dataAddCartSuccess,
          type: action.type,
        }
      };
    case ADD_CART_FAILED: 
      return {
        ...state,
        cartReducer: {
          ...state.cartReducer,
          errors: action.payload,
          type: action.type,
        }
      };
    case RESET_CART_ERRORS: 
      return {
        ...state,
        cartReducer: {
          ...state.cartReducer,
          errors: null,
          type: action.type,
        }
      };
    case DELETE_CART:
      let cartIdArr = action.payload.cart_id;
      let _dataDelCarts = [];
      _dataDelCarts = state.cartReducer?.data.filter(
        (item: any) => !cartIdArr.includes(item.id)
      );
      return {
        ...state,
        cartReducer: {
          ...state.cartReducer,
          loadingDeleteCart: true,
          data: _dataDelCarts,
          type: action.type,
        }
      };
      break;
    case DELETE_CART_SUCCESS:
      return {
        ...state,
        cartReducer: {
          ...state.cartReducer,
          loadingDeleteCart: false,
          type: action.type,
        }
      };
      break;
    case DELETE_CART_FAILED:
      return {
        ...state,
        cartReducer: {
          ...state.cartReducer,
          loadingDeleteCart: false,
          type: action.type,
        }
      };
      break;
    case INCREASE_QUANTITY:
      let _dataIncrease = [...state.cartReducer.data];
      let idxInc = _dataIncrease.findIndex(
        (x: any) => x.id === action.payload.id
      );
      _dataIncrease[idxInc].quantity++;
      _dataIncrease[idxInc].qty++;
      _dataIncrease[idxInc].priceByQuantity =
        +_dataIncrease[idxInc].prod_price * _dataIncrease[idxInc].quantity;

      localStorage.setItem("carts", JSON.stringify(_dataIncrease));

      return {
        ...state,
        cartReducer: {
          ...state.cartReducer,
          data: _dataIncrease,
          type: action.type,
        }
      };
    case DECREASE_QUANTITY:
      let _dataDecrease = [...state.cartReducer.data];
      let idxDec = _dataDecrease.findIndex(
        (x: any) => x.id === action.payload.id
      );
      let quantity = _dataDecrease[idxDec].quantity;
      if (quantity > 1) {
        _dataDecrease[idxDec].quantity--;
        _dataDecrease[idxDec].qty--;
        _dataDecrease[idxDec].priceByQuantity =
          +_dataDecrease[idxDec].prod_price * _dataDecrease[idxDec].quantity;
      }
      localStorage.setItem("carts", JSON.stringify(_dataDecrease));
      return {
        ...state,
        cartReducer: {
          ...state.cartReducer,
          data: _dataDecrease,
          type: action.type,
        }
      };
    case UPDATE_QUANTITY:
      let quantityUpdate = action.payload.quantity;
      let _dataUpdateQuantity = [...state.cartReducer.data];
      let idxUpdate = _dataUpdateQuantity.findIndex(
        (x: any) => x.id === action.payload.id
      );
      if (quantityUpdate >= 1) {
        _dataUpdateQuantity[idxUpdate].quantity = quantityUpdate;
        _dataUpdateQuantity[idxUpdate].qty = quantityUpdate;
        _dataUpdateQuantity[idxUpdate].priceByQuantity =
          +state.cartReducer.data[idxUpdate].prod_price * quantityUpdate;
      }
      localStorage.setItem("carts", JSON.stringify(_dataUpdateQuantity));
      return {
        ...state,
        cartReducer: {
          ...state.cartReducer,
          data: _dataUpdateQuantity,
          type: action.type,
        }
      };
    case UPDATE_OR_CREATE_CART:
      let dataRedux = [...state.cartReducer.data];
      let dataOrder = [...action.payload.dataCarts];
      console.log(dataRedux, 'dataRedux');
      console.log(dataOrder, 'dataOrder');
      
      // const mergeArray = (dataRedux: any, dataOrder: any) => {
      //   return [...dataRedux, ...dataOrder].reduce((acc: any, val: any) => {
      //     const val1 = { ...val };
      //     // console.log(acc, val1, 'ppppppppppp');
          
      //     const ind = acc.findIndex((el: any) => el.prod_id === val1.prod_id);
      //     // console.log(ind, 'ind ind');
      //     // console.log(action.payload.isSelected, 'action.payload.isSelected');
          
      //     if (ind !== -1) {
      //       acc[ind].quantity += val1.qty;
      //       acc[ind].qty += val1.qty;
      //       acc[ind].isSelected = action.payload.isSelected;
      //       acc[ind].priceByQuantity += +val1.qty * +val1.prod_price;
      //     } else {
      //       // console.log(val1, 'val1');
            
      //       acc.unshift({
      //         ...val1,
      //         quantity: val1.qty,
      //         isSelected: true,
      //         priceByQuantity: +val1.qty * +val1.prod_price,
      //       });
      //       // acc.push({
      //       // 	...val1,
      //       // 	quantity: val1.qty,
      //       // 	isSelected: true,
      //       // 	priceByQuantity: +val1.qty * +val1.prod_price
      //       // });
      //     }
      //     return acc;
      //   }, []);
      // };

      // let _dataRes = mergeArray(dataRedux, dataOrder);

      dataOrder.map((obj1: any) => {
        const found = dataRedux.findIndex((obj2: any) => obj2.prod_id === obj1.prod_id);
        if (found !== -1) {
          console.log(obj1, 'update');
          console.log(dataRedux[found].quantity + obj1.qty);
          
          dataRedux[found].quantity = dataRedux[found].quantity + obj1.qty;
          dataRedux[found].qty = dataRedux[found].qty + obj1.qty;
          dataRedux[found].isSelected = action.payload.isSelected;
          dataRedux[found].priceByQuantity += +obj1.qty * +obj1.prod_price;
        }else{
          console.log('create');
          
          dataRedux.unshift({
            ...obj1,
            quantity: obj1.qty,
            isSelected: true,
            priceByQuantity: +obj1.qty * +obj1.prod_price,
          });
        }
      });
      localStorage.setItem("carts", JSON.stringify(dataRedux));
      return {
        ...state,
        cartReducer: {
          ...state.cartReducer,
          data: dataRedux,
          type: action.type,
        }
      };
      break;
    case UPDATE_STATUS_PRODUCT:
      let _dataUpdateStatus = action.payload.dataCarts;
      console.log(_dataUpdateStatus, '_dataUpdateStatus');
      
      let _dataUpdatedStatus = [];
      _dataUpdatedStatus = state.cartReducer.data.map((item: any) => {
        const index = _dataUpdateStatus.findIndex(
          (el: any) => el.id === item.id
        );
        if (index !== -1) {
          return {
            ...item,
            isSelected: action.payload.isSelected,
          };
        }
        return item;
      });

      localStorage.setItem("carts", JSON.stringify(_dataUpdatedStatus));
      return {
        ...state,
        cartReducer: {
          ...state.cartReducer,
          data: _dataUpdatedStatus,
          type: action.type,
        }
      };
      break;
    
    //order history
    case GET_LIST_ORDER_HISTORY_REQUEST:
      let listsData = [];
      if (action.payload?.page > 1) {
        listsData = state.orderHistoryReducer?.lists;
      }

      return {
        ...state,
        orderHistoryReducer: {
          ...state.orderHistoryReducer,
          lists: listsData,
          listParams: {
            start_date: action.payload.start_date,
            end_date: action.payload.end_date,
            page: action.payload.page,
            time_option: action.payload.time_option,
            reload: action.payload.reload,
          },
          loadingListOrderHistory: true,
          type: action.type,
          cancelOrderFailed: null
        }
      };
      break;
    case GET_LIST_ORDER_HISTORY_SUCCESS:
      let {data} = action.payload;

      let listData:any = [];
      if (data?.current_page === 1) {
        listData = [];
      } else {
        listData = state.orderHistoryReducer?.lists?.data;
      }
      
      console.log('data', data);
      
     if (data.data && data.data.length > 0) {
      if (data.data[0]?.id && data.data[0]?.payment_method === "CARD" && data.data[0]?.payment_status === "PENDING") {
        data.data[0] = {...data.data[0], payment_status: 'FAILED'}
      }
     }

      return {
        ...state,
        orderHistoryReducer: {
          ...state.orderHistoryReducer,
          lists: {
            data: [...listData, ...data.data],
            total: data.total,
            totalPage: data.last_page,
          },
          listParams: state.orderHistoryReducer.listParams,
          loadingListOrderHistory: false,
          type: action.type,
        }
      };
      break;
    case GET_LIST_ORDER_HISTORY_FAILED:
      return {
        ...state,
        orderHistoryReducer: {
          ...state.orderHistoryReducer,
          loadingListOrderHistory: false,
          type: action.type,
        }
      };
      break;
    case UPDATE_RELOAD_PAGE_LIST:
      return {
        ...state,
        orderHistoryReducer: {
          ...state.orderHistoryReducer,
          listParams: {
            ...state.orderHistoryReducer.listParams,
            reload: action.payload.reload,
          },
          loadingListOrderHistory: false,
          type: action.type,
        }
      };
      break;
    case CANCEL_ORDER_REQUEST:
      console.log(action.payload, 'CANCEL_ORDER_REQUEST');
      return {
        ...state,
        orderHistoryReducer: {
          ...state.orderHistoryReducer,
          type: action.type,
          cancelOrderFailed: null
        }
      };
      break;
    case CANCEL_ORDER_SUCCESS:
        console.log(action.payload, 'CANCEL_ORDER_SUCCESS');
        const _listData = [...state.orderHistoryReducer.lists.data];
        let idxList = _listData.findIndex(
          (x: any) => x.id === action.payload.data.id
        );

        _listData[idxList].status = action.payload.data.status;
        _listData[idxList].cancel_at = action.payload.data.cancel_at;
        _listData[idxList].cancel_reason = action.payload.data.cancel_reason;

        return {
          ...state,
          orderHistoryReducer: {
            ...state.orderHistoryReducer,
            lists: {
              ...state.orderHistoryReducer.lists,
              data: [..._listData],
            },
            type: action.type,
          }
        };
      break;
    case CANCEL_ORDER_FAILED:
        console.log(action.payload, 'CANCEL_ORDER_FAILED');
        return {
          ...state,
          orderHistoryReducer: {
            ...state.orderHistoryReducer,
            type: action.type,
            cancelOrderFailed: action.payload
          }
        };
      break;
    //payment
    case UPDATE_DATA_PAYMENT:
      return { 
        ...state,
        paymentReducer: {
          ...state.paymentReducer,
          data: {
            ...state.paymentReducer.data,
            ...action.payload
          },
          type: action.type
        }
      };
    break;
    case GET_STORES:
      return { 
        ...state,
        paymentReducer: {
          ...state.paymentReducer,
          loadingStores: true,
          type: action.type
        },
        cartReducer: {
          ...state.cartReducer,
          type: "",
        }
      };
    case GET_STORES_SUCCESS:
      return { 
        ...state,
        paymentReducer: {
          ...state.paymentReducer,
          data: {
            ...state.paymentReducer.data,
            stores: action.payload.data,
          },
          loadingStores: false,
          type: action.type
        }
      };
    break;
    case GET_LAST_ORDER:
      return { 
        ...state,
        paymentReducer: {
          ...state.paymentReducer,
          loadingLastOrder: true,
          type: action.type
        },
        cartReducer: {
          ...state.cartReducer,
          type: "",
        }
      };
    case GET_LAST_ORDER_SUCCESS:
      let methodObj = PAYMENT_METHOD.find((item: any) => item.value === action.payload.data?.payment_method);
      return { 
        ...state,
        paymentReducer: {
          ...state.paymentReducer,
          data: {
            ...state.paymentReducer.data,
            lastOrder: action.payload.data,
            paymentMethod: methodObj
          },
          loadingLastOrder: false,
          type: action.type
        }
      };
    break;

    case RESET_DATA_PAYMENT:
      return { 
        ...state,
        paymentReducer: {
          ...state.paymentReducer,
          data: {
            orderId: null,
            buyerName: "",
            phone: "",
            shippingTime: {
              date: null,
              hour: null,
              minute: null,
            },
            paymentMethod: { id: null, label: '', value: '' },
            depositorName: "",
            policyPayment: false,
            shippingNoteContent: "",
            shippingNoteId: null,
            dataProduct: [],
            shippingAddress: {},
            stores: [],
            lastOrder: {},
            cardManualInfo: {
              card_number_1: '',
              card_number_2: '',
              card_number_3: '',
              card_number_4: '',
              expire_month: '',
              expire_year: '',
              installment_month: { id: 1, label: '일시불', value: '00'},
            },
            type: 'DETAIL_PAYMENT' // 'CART_PAYMENT'
          },
          type: action.type
        }
      };
      default:
      return state;
  }
};

export default orderReducer;
