// import { call, put, takeLatest } from 'redux-saga/effects';

import API from "core/networks/API";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import { API_CART, API_ORDER_HISTORY } from "../api";
import {
  GET_LIST_CART,
  GET_LIST_CART_SUCCESS,
  ADD_CART_SUCCESS,
  DELETE_CART_SUCCESS,
  DELETE_CART_FAILED,
  DELETE_CART,
  ADD_CART,
  INCREASE_QUANTITY,
  DECREASE_QUANTITY,
  UPDATE_QUANTITY,
  GET_LIST_CART_FAILED,
  ADD_CART_FAILED,
  UPDATE_CART_FAILED,
  GET_LIST_ORDER_HISTORY_SUCCESS,
  GET_LIST_ORDER_HISTORY_FAILED,
  GET_LIST_ORDER_HISTORY_REQUEST,
  CANCEL_ORDER_REQUEST,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_FAILED,
  GET_STORES,
  GET_STORES_SUCCESS,
  GET_STORES_FAILED,
  GET_LAST_ORDER,
  GET_LAST_ORDER_SUCCESS,
  GET_LAST_ORDER_FAILED,
} from "./types";
import { API_PRODUCT } from "modules/Products/api";

function* getListCarts(_action: any): any {
  try {
    const response = yield call(() => {
      let url = API_CART.CART_LIST;
      return API.get(url, _action.payload);
    });
    const { data } = response;
    // yield put(listCartSuccess(data))
    if (data?.code === 200) {
      yield put({
        type: GET_LIST_CART_SUCCESS,
        payload: data,
      });
    }else{
      yield put({
        type: GET_LIST_CART_FAILED,
        payload: data?.error || {},
      });
    }
  } catch (error) {
    yield put({
      type: GET_LIST_CART_FAILED,
      payload: error || {},
    });
  }
}

function* addToCart(_action: any): any {
  // yield delay(500);
  try {
    const response = yield call(() => {
      let url = API_CART.CART_ADD;
      return API.post(url, {
        quantity: _action.payload.quantity,
        product_id: _action.payload.prod_id,
      });
    });
    const { data } = response;
    if (data?.code === 200) {
      yield put({
        type: ADD_CART_SUCCESS,
        payload: data,
      });
    }else{
      yield put({
        type: ADD_CART_FAILED,
        payload: data?.error || {},
      });
    }
  } catch (error: any) {
    yield put({
      type: ADD_CART_FAILED,
      payload: error?.response?.data || [],
    });
  }
}

function* updateCart(_action: any): any {
  yield delay(500);
  try {
    const response = yield call(() => {
      let url = API_CART.CART_UPDATE;
      return API.put(url, {
        cart_id: _action.payload.id,
        quantity: _action.payload.quantity,
      });
    });
    const { data } = response;
    if (data?.code === 200) {
      
    }else{
      yield put({
        type: UPDATE_CART_FAILED,
        payload: data?.error || {},
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_CART_FAILED,
      payload: error || {},
    });
  }
}

function* deleteCart(_action: any): any {
  try {
    const response = yield call(() => {
      let url = API_CART.CART_DELETE;
      return API.post(url, _action.payload);
    });
    const { data } = response;
    console.log(data, "deleteCart");
    if (data.code === 200) {
      yield put({
        type: DELETE_CART_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: DELETE_CART_FAILED,
        payload: data?.error || {},
      });
    }
  } catch (error) {
    yield put({
      type: DELETE_CART_FAILED,
      payload: error,
    });
  }
}

function* getListOrderHistory(_action: any): any{
  try {
    const response = yield call(() => {
      let url = API_ORDER_HISTORY.GET_LIST;
      return API.get(url, _action.payload);
    });
    const { data } = response;
    
    if (data?.code === 200) {
      yield put({
        type: GET_LIST_ORDER_HISTORY_SUCCESS,
        payload: data,
      });
    }else{
      yield put({
        type: GET_LIST_ORDER_HISTORY_FAILED,
        payload: data?.error || {},
      });
    }
  } catch (error) {
    yield put({
      type: GET_LIST_ORDER_HISTORY_FAILED,
      payload: error || {},
    });
  }

}

function* cancelOrder(_action: any): any {
  try {
    const response = yield call(() => {
      let url = API_ORDER_HISTORY.CANCEL_ORDER;
      return API.post(url, _action.payload);
    });

    const { data } = response;
    if (data?.code === 200) {
      yield put({
        type: CANCEL_ORDER_SUCCESS,
        payload: data,
      });
    }else{
      yield put({
        type: CANCEL_ORDER_FAILED,
        payload: data?.error || {},
      });
      
    }
  } catch (error: any) {
    yield put({
      type: CANCEL_ORDER_FAILED,
      payload: error?.response?.data || {},
    });
  }
}

function* getStores(_action: any): any{
  try {
    const response = yield call(() => {
      let url = API_PRODUCT.GET_STORES;
      return API.get(url, _action.payload);
    });
    const { data } = response;
    if (data?.code === 200) {
      yield put({
        type: GET_STORES_SUCCESS,
        payload: data,
      });
    }else{
      yield put({
        type: GET_STORES_FAILED,
        payload: data?.error || {},
      });
    }
  } catch (error) {
    yield put({
      type: GET_STORES_FAILED,
      payload: error || {},
    });
  }
}

function* getLastOrder(_action: any): any {
  try {
    const response = yield call(() => {
      let url = API_ORDER_HISTORY.GET_LIST;
      return API.get(url, _action.payload);
    });
    const { data } = response;
    if (data?.code === 200) {
      yield put({
        type: GET_LAST_ORDER_SUCCESS,
        payload: data,
      });
    }else{
      yield put({
        type: GET_LAST_ORDER_FAILED,
        payload: data?.error || {},
      });
    }
  } catch (error) {
    yield put({
      type: GET_LAST_ORDER_FAILED,
      payload: error || {},
    });
  }
}

function* cartSaga() {
  yield takeLatest(GET_LIST_CART, getListCarts);
  yield takeLatest(DELETE_CART, deleteCart);
  yield takeLatest(ADD_CART, addToCart);
  yield takeLatest(INCREASE_QUANTITY, updateCart);
  yield takeLatest(DECREASE_QUANTITY, updateCart);
  yield takeLatest(UPDATE_QUANTITY, updateCart);

  yield takeLatest(GET_LIST_ORDER_HISTORY_REQUEST, getListOrderHistory);
  yield takeLatest(CANCEL_ORDER_REQUEST, cancelOrder);
  yield takeLatest(GET_STORES, getStores);
  yield takeLatest(GET_LAST_ORDER, getLastOrder);
}

export default cartSaga;
