import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RouterName } from "core/router";
import { useCartVerify, useCarts, useDeleteCarts, useGetCarts, useProductSelectedInCarts, useUpdateCart } from "../hooks/useCarts";
import { updateStatusProduct } from "../store/actions";
import { useCountSize } from "core/hooks/useCountSize";
import CDialog from "core/components/CDialog";
import CEmptyIcon from "core/components/CEmptyIcon";
import SkeletonComponent from "core/components/Skeleton/skeleton";
import IconHeader from "core/components/header/IconHeader";
import AppLayout from "core/layouts/AppLayout";
import NoCartIcon from "../assets/icon/no_cart.svg";
import "../assets/scss/cartlistpage.scss";
import { useAddress } from "modules/Products/hooks/useAddress";
import { useUpdateReload } from "../hooks/useLists";
import { useDataPayment, useUpdateDataPayment } from "../hooks/usePayment";
import CartTotalComponent from "../components/CartTotalComponent";
import CartItemComponent from "../components/CartItemComponent";
import CartPaymentTotalComponent from "../components/CartPaymentTotalComponent";
import ModalOrderComponent from "../components/ModalOrderComponent";
import { useLastOrder, useStores } from "../hooks/useOrder";
import { PRODUCT_STATE } from "core/constants/Commons";

const CartList = (props: any) => {
  const loadingCartList = useSelector((state: any) => state.Carts?.loadingListCart);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  // const [loadingLastOrder] = useLastOrder({});
  const [loadingStores] = useStores({});
  const [dataCarts, handleGetCarts] = useGetCarts({});
  const productSelectedInCarts = useCarts({isSelected: true});
  const [paramsAddress] = useState<any>({
    default: "Y",
    first: true,
  });
  const [, dataAddress] = useAddress(paramsAddress);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [dataCartSelected, setDataCartSelected] = useState<any>({});
  const [prodNotExist, setProdNotExist] = useState<any>({
    prodDeleted: [],
    prodOutOffStock: [],
    dataCartDel: [],
  })
  const [modalDialog, setModalDialog] = useState<any>({
    isShow: false,
    title: "",
    content: "",
    type: "",
  });
  const [isLoading, onIncrease, onDecrease, onUpdate] = useUpdateCart({});
  const [isLoadingDel, onDelete] = useDeleteCarts({});
  const updateReload = useUpdateReload();
  const [paramsCountSize, setParamsCountSize] = useState<any>(false);
  useCountSize(paramsCountSize)

  useEffect(() => {
    handleGetCarts();
    updateReload({reload: 0});
    (window as any).webViewCallGetListPath(['/playground?', '/product/inquiry?', '/playground/search?', '/product/inquiry/detail/', '/carts-list'])
  }, [])

  useEffect(() => {
    let _outOffStock: any = [];
    let _deletedProd: any = [];
    let _dataCartDel: any = [];
    console.log(dataCarts, 'dataCarts');
    const _dataCart = dataCarts.filter((item: any) => item?.product?.id);
    _dataCart.map((item: any) => {
      if (item?.product?.state !== PRODUCT_STATE.AVAILABLE && !item?.product?.deleted_at) {
        _outOffStock = [..._outOffStock, item.prod_name]
        _dataCartDel = [..._dataCartDel, item];
      }
      if (item?.product?.deleted_at) {
        _deletedProd = [..._deletedProd, item.prod_name]
        _dataCartDel = [..._dataCartDel, item];
      }
    });

    
    setProdNotExist(({
      prodDeleted: _deletedProd,
      prodOutOffStock: _outOffStock,
      dataCartDel: _dataCartDel
    }))
  }, [dataCarts])

  console.log('prodNotExist', prodNotExist);
  
  useEffect(() => {
    if (prodNotExist.prodDeleted.length > 0 || prodNotExist.prodOutOffStock.length > 0) {
      const _contentName = prodNotExist.prodOutOffStock.length ? `${prodNotExist.prodOutOffStock.toString()} 상품이 품절되었습니다.` : '';  
      const _contentDelete = prodNotExist.prodDeleted.length ? `${prodNotExist.prodDeleted.toString()} 상품이 변경 혹은 삭제되어 존재하지 않습니다` : '';  
      setModalDialog(({
        isShow: true,
        title: "",
        content: `${_contentName}</br />${_contentDelete}`,
        code: 8027,
        type: "CART_VERIFY",
      }))
    }
  }, [prodNotExist.prodDeleted.length, prodNotExist.prodOutOffStock.length])

  const removeCart = (data: any) => {
    if (onDelete) {
      onDelete(data)
    }
  };

  const handleIncreaseQuatity = (item: any) => {
    if (onIncrease) {
      onIncrease(item);
    }
  };

  const handleDecreaseQuatity = (item: any) => {
    if (onDecrease) {
      onDecrease(item);
    }
  };

  const removeCartSelected = () => {
    if (onDelete) {
      onDelete(productSelectedInCarts)
    }
  };

  const [handleUpdate, ] = useUpdateDataPayment({})
  const [, dataPayment] = useDataPayment({});

  const _verifySuccess = () => {
    if (dataAddress && dataAddress?.id) {
      handleUpdate({
        ...dataPayment,
        type: 'CART_PAYMENT'
      })
      navigate(RouterName.PAYMENT, {
        state: {
          type: "IN_CART",
        },
      });
    } else {
      setModalDialog({
        isShow: true,
        title: "",
        content: "주소를 설정해 주세요.",
        type: "NO_ADDRESS",
      });
    }
  }
  const _verifyFailed = (errors: any) => {
    console.log(107, errors);
    setModalDialog(({
      isShow: true,
      title: "",
      content: errors.msg,
      code: errors?.code || 8200,
      type: "CART_VERIFY",
    }))
  }

  const [loadingVerify, handleCartVerify] = useCartVerify({onSuccess: _verifySuccess, onFailed: _verifyFailed})
  const productCartSelected = useProductSelectedInCarts({});
  const orderPaymentPage = () => {
    handleCartVerify(productCartSelected, 'cart')
  };

  const handleConfirm = () => {
    if (modalDialog.type === "NO_ADDRESS") {
      navigate(RouterName.ADDRESS_BOOK, {
        state: {
          back_type: RouterName.CARTS_LIST, 
          back_url: RouterName.CARTS_LIST,
        },
      });
    }
    if (modalDialog.type === "CART_VERIFY") {
      switch (modalDialog?.code) {
        case 8200:
          handleGetCarts();
          dispatch(updateStatusProduct({dataCarts, isSelected: true}));
          navigate(RouterName.GOODS_LIST, {
            state: {
              back_type: "", 
              back_url: "",
            },
          });
          break;
        case 8201: 
          handleGetCarts();
          // dispatch(updateStatusProduct({dataCarts, isSelected: true}));
          setModalDialog({
            isShow: false,
            title: "",
            content: "",
            type: "",
          });
          _verifySuccess()
        break;
        case 8027:
          if (onDelete) {
            onDelete(prodNotExist.dataCartDel)
          }
          navigate(RouterName.GOODS_LIST);
          break;
        default:
          navigate(RouterName.GOODS_LIST, {
            state: {
              back_type: "", 
              back_url: "",
            },
          });
          break;
      }
    }
  };

  const handleCancel = () => {
    setModalDialog({
      isShow: false,
      title: "",
      content: "",
      type: "",
    });
  };

  useEffect(() => {
    setParamsCountSize(loadingCartList);
  }, [loadingCartList]);

  const handleShowKeyBoard = (data: any) => {
    setDataCartSelected({ ...data.dataItem, index: data.index });
    setIsShowModal(true);
  };

  const handleStatus = (value: boolean) => {
    setIsShowModal(value);
  };

  const addCart = () => {};

  const onTypingModal = (data: any, quantity: number) => {
    if (onUpdate) {
      let dataUpdate = {...data, quantity};
      onUpdate(dataUpdate)
    }
  };

  const onSelectRow = (data: any) => {
    if (data.id === 'all') {
      dispatch(updateStatusProduct({dataCarts, isSelected: data.checked}));
    }else{
      dispatch(updateStatusProduct({dataCarts: [data], isSelected: data.isSelected}));
    }
  }

  const renderCarts = (dataCarts: any) => {
    if (dataCarts && dataCarts.length > 0) {
      return (
        <main>
          <div className="cart-list-page__content">
            <CartTotalComponent
              dataCarts={dataCarts}
              productSelectedInCarts={productSelectedInCarts}
              selectAll={onSelectRow}
              removeCartSelected={removeCartSelected}
            />
            <CartItemComponent
              cartList={dataCarts}
              isCheckbox={true}
              productSelectedInCarts={productSelectedInCarts}
              removeCart={removeCart}
              selectItem={onSelectRow}
              increaseQuatity={handleIncreaseQuatity}
              decreaseQuatity={handleDecreaseQuatity}
              handleShowKeyBoard={handleShowKeyBoard}
            />
          </div>
          <div className="cart-list-page__total" id="display-panel">
            <CartPaymentTotalComponent
              carts={dataCarts}
              productSelectedInCarts={productSelectedInCarts}
              orderPayment={orderPaymentPage}
            />
          </div>
        </main>
      );
    } else {
      return (
        <main className="no-cart">
          <div className="no-cart__content">
            <CEmptyIcon
              className={"cart-list-empty"}
              icon={NoCartIcon}
              text={"장바구니에 담긴 상품이 없습니다."}
              description={""}
            />
            <div className="d-grid">
              <button
                className="cbtn btn-primary"
                onClick={() => navigate(RouterName.GOODS_LIST)}
              >
                상품 보러가기
              </button>
            </div>
          </div>
        </main>
      );
    }
  };

  const handleGoBack = () => {
    dispatch(updateStatusProduct({dataCarts, isSelected: true}));
    if (location?.state && location.state?.back_type) {
      navigate(location.state?.back_url, {
        state: {}
      })
    }else{
      navigate(-1)
    }
  }

  const goToMain = () => {
    dispatch(updateStatusProduct({dataCarts, isSelected: true}));
    navigate(RouterName.MAIN, {state: {}});
  }

  (window as any).backFromDeviceAndroid = function() {
    //code webview
    handleGoBack()
  }

  return (
    <AppLayout className={"cart-list-page"}>
      <IconHeader
        title={"장바구니"}
        iconLeft={true}
        handleLeftClick={() => handleGoBack()}
        handleRightClick={() => goToMain()}
        iconRight={true}
        id={"main-header"}
      />

      {loadingCartList ? (
        <main>
          <div className="cart-list-page__content list-panel">
            <CartTotalComponent
              dataCarts={[]}
              productSelectedInCarts={[]}
              selectAll={() => {}}
              removeCartSelected={() => {}}
            />
            <div className="cart-item cart-item--loading"  id="list-panel">
              <div className="item-loading">
                <SkeletonComponent
                  width={72}
                  className={"loading-img"}
                  count={1}
                  height={72}
                  circle={false}
                />
                <div className="left-loading">
                  <SkeletonComponent width={'70%'} height={19}/>
                  <SkeletonComponent width={'30%'} height={19}/>
                </div>
              </div>
              <div className="item-loading">
                <SkeletonComponent
                  width={72}
                  className={"loading-img"}
                  count={1}
                  height={72}
                  circle={false}
                />
                <div className="left-loading">
                  <SkeletonComponent width={'70%'} height={19}/>
                  <SkeletonComponent width={'30%'} height={19}/>
                </div>
              </div>
              <div className="item-loading">
                <SkeletonComponent
                  width={72}
                  className={"loading-img"}
                  count={1}
                  height={72}
                  circle={false}
                />
                <div className="left-loading">
                  <SkeletonComponent width={'70%'} height={19}/>
                  <SkeletonComponent width={'30%'} height={19}/>
                </div>
              </div>
            </div>
          </div>
          <div className="cart-list-page__total" id="display-panel">
            <CartPaymentTotalComponent
              carts={[]}
              productSelectedInCarts={[]}
              orderPayment={orderPaymentPage}
            />
          </div>
        </main>
      ) : (
        renderCarts(dataCarts)
      )}

      <ModalOrderComponent
        info={dataCartSelected}
        isShow={isShowModal}
        handleStatusOrder={handleStatus}
        title={dataCartSelected.prod_name}
        addCart={addCart}
        isShowPayment={false}
        onIncreaseModal={handleIncreaseQuatity}
        onDecreaseModal={handleDecreaseQuatity}
        onTypingModal={onTypingModal}
        className={"bottom-swiper--keyboard"}
      />

      <CDialog
        id={"js-error-quantity"}
        className={`modal-dialog-no-address ${modalDialog.type === 'CART_VERIFY' && "msg-cart-verify"}`}
        show={modalDialog.isShow || false}
        title={modalDialog.title}
        submitBtn={modalDialog.type !== 'CART_VERIFY' ? "주소 설정" : '확인'}
        closeBtn={modalDialog.type !== 'CART_VERIFY' ? "닫기" : ''}
        overlay
        handleSubmit={() => handleConfirm()}
        handleClose={() => handleCancel()}
      >
        <div className="content-dialog" dangerouslySetInnerHTML={{__html: modalDialog?.content || ""}}>
        </div>
      </CDialog>
    </AppLayout>
  );
};

export default CartList;
