import CDropdown from "core/components/form-controls/CDropdown";
import CFormControl from "core/components/form-controls/CFormControl";
import CTextArea from "core/components/form-controls/CTextArea";
import { phoneNumberFormat } from "core/utils/helper";
import { memo, useEffect, useState } from "react";
import GenerateDateTimeComponent from "./GenerateDateTimeComponent";
import { DELIVERY_DATA } from "core/constants/Commons";
import CAccoditionItem from "core/components/CAccoditionItem";
import { useUpdateDataPayment } from "../hooks/usePayment";

interface OrderInformation {
  dataOrders: any;
  handleChangeAddress: Function,
  user: any
}

interface ObjectDelivery {
  id?: number;
  label?: string;
}


const OrderInformationComponent = ({ dataOrders, handleChangeAddress, user }: OrderInformation) => {
  const [handleUpdate, ] = useUpdateDataPayment({})
  const [dataOrderInformation, setDataOrderInformation] = useState<any>({
    shippingTime: dataOrders?.shippingTime || {},
    shippingNoteContent: dataOrders?.shippingNoteContent || '',
    shippingNoteId: dataOrders?.shippingNoteId || null
  });

  console.log('OrderInformationComponent');
  
  useEffect(() => {
    setDataOrderInformation(
      {
        shippingTime: dataOrders?.shippingTime || {},
        shippingNoteContent: dataOrders?.shippingNoteContent || '',
        shippingNoteId: dataOrders?.shippingNoteId || null
      }
    )
  }, [])

  useEffect(() => {
    handleUpdate({
      ...dataOrders,
      shippingTime: dataOrderInformation.shippingTime,
      shippingNoteContent: dataOrderInformation.shippingNoteContent,
      shippingNoteId: dataOrderInformation.shippingNoteId
    })
  }, [dataOrderInformation.shippingTime, dataOrderInformation.shippingNoteContent, dataOrderInformation.shippingNoteId])
  
  const handleInputChange = (e: any) => {
    if (e.target.value.length > 50) return;
    setDataOrderInformation({
      ...dataOrderInformation,
      [e.target.name]: e.target.value || ''
    })
  };

  const handleDeliveryRequest = (e: ObjectDelivery) => {
    setDataOrderInformation({
      ...dataOrderInformation,
      shippingNoteId: e.id || null,
      shippingNoteContent: e.id !== 7 ? e.label : ''
    })
  };

  const changeAddress = (value: string) => {
    handleChangeAddress(value)
  }

  const dateTimeSelected = (shippingTime: any) => {
    console.log('dateTimeSelected', shippingTime);
    
    setDataOrderInformation({
      ...dataOrderInformation,
      shippingTime
    })
  }

  console.log('dataOrderInformation 79', dataOrderInformation);
  
  const contentInfo = <div className="order-info-accodition-content">
    <div className="orderer-info">
      <label className="title">
        주문자 정보 <span>*</span>
      </label>
      <div className="row">
        <div className="col-4">
          <CFormControl
            placeholder={"구매자"}
            type={"input"}
            required={true}
            disabled={true}
            readonly={false}
            name={"buyerName"}
            className={"user-name"}
            maxLength={20}
            value={user?.userInfo?.name || dataOrders?.buyerName}
            handleChange={(e:any) => handleInputChange(e)}
            // error={"구매자명을 입력해주세요."}
            // warning={""}
          />
        </div>
        <div className="col-8">
          <CFormControl
            placeholder={''}
            type={"input"}
            required={true}
            disabled={true}
            readonly={false}
            name={"phone"}
            value={phoneNumberFormat(user?.userInfo?.phone || dataOrders?.phone)}
            className={"phone-payment"}
            handleChange={() => {}}
            // error={"This is the error message"}
            // warning={""}
          />
        </div>
      </div>
    </div>
    <div className="shipping-info">
      <label className="title">
        배송지 정보 <span>*</span>
      </label>
      <div className="company-lunch">
        {
          dataOrders.shippingAddress.id ? <>
          <div className="address-name">
            <h2>{dataOrders.shippingAddress?.shipping_address_name} {dataOrders.shippingAddress.default === 'Y' && <span className="default-address">기본배송지</span>}</h2>
            <button className="btn" disabled={dataOrders?.orderId} type="button" onClick={() => handleChangeAddress('update')}>변경</button>
          </div>
            <div className="buyer">
              <label htmlFor="" className="box-content-address">
                <span>받으시는 분</span> 
                {dataOrders.shippingAddress?.shipping_name}
              </label>
            </div>
            <div className="address">
              <div className="box-content-address">
                <div className="title-address">주소</div> 
                <div className="content-address">
                  <p className="address-zipcode">
                  {dataOrders.shippingAddress?.zipcode ? `(${dataOrders.shippingAddress?.zipcode}) ` : ''} 
                  {dataOrders.shippingAddress?.address || ''} 
                  </p>
                  <p className="address-zipcode">
                    {dataOrders.shippingAddress?.detail_address || ''}
                  </p>
                </div>
              </div>
            </div>
            <div className="phone">
              <label htmlFor="" className="box-content-address">
                <span>휴대폰번호</span>
                {phoneNumberFormat(dataOrders.shippingAddress?.shipping_phone)}
              </label>
            </div>
            {
              dataOrders.shippingAddress?.shipping_contact && 
              <div className="phone">
                <label htmlFor="" className="box-content-address">
                  <span>추가 연락처</span>
                  {phoneNumberFormat(dataOrders.shippingAddress?.shipping_contact)}
                </label>
              </div>
            }
          </> : <>
            <div className="no-address-payment">
              <h2>
                등록된 배송지가 없습니다.
              </h2>
              <label htmlFor="">배송지를 설정해주세요.</label>
              <button type="button" className="btn btn-secondary btn-address-setting" onClick={() => handleChangeAddress('create')}>
                <span>배송지 설정</span>
              </button>
            </div>
          </>
        }
        
      </div>
    </div>
    <div className="order-time">
      <label className="title">배달 요청 시간 <span>*</span></label>
      <GenerateDateTimeComponent dataOrders={dataOrders} dateTimeSelected={dateTimeSelected} dataShippingTime={dataOrderInformation.shippingTime}/>
    </div>
    <div className="delivery-request">
      <h2>배달요청 사항</h2>
      <CFormControl type={"empty"} className="delivery-select">
        <CDropdown
          name={"delivery_request"}
          dataSet={DELIVERY_DATA}
          selected={DELIVERY_DATA.find((item:any) => item.id === dataOrders.shippingNoteId)}
          placeholder={"선택"}
          disabled={dataOrders?.orderId}
          handleChange={handleDeliveryRequest}
        />
      </CFormControl>
      {
        dataOrderInformation?.shippingNoteId === 7 && 
        <div className="direct-area">
          <CFormControl type={"empty"}>
            <CTextArea
              rows={6}
              placeholder={"배달 요청 사항을 입력해 주세요."}
              disabled={dataOrderInformation?.shippingNoteId !== 7 || dataOrders?.orderId}
              maxLength={50}
              value={dataOrderInformation?.shippingNoteContent || ''}
              name={"shippingNoteContent"}
              handleChange={(e:any) => handleInputChange(e)}
            />
          </CFormControl>
        </div>
      }
      
    </div>
  </div>

  return (
    <CAccoditionItem
      id={1}
      className={'order-info-accodition'}
      question={'배달 정보'}
      answer={contentInfo}
      key={1}
      isShow={!dataOrders?.orderId}
      type={1}
      title={""}
      description={""}
    />
  );
};

export default memo(OrderInformationComponent);
